
    function _extends() {
        _extends = Object.assign || function (target) {
            for (var i = 1; i < arguments.length; i++) {
                var source = arguments[i];

                for (var key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }

            return target;
        };

        return _extends.apply(this, arguments);
    }

    var runningOnBrowser = typeof window !== "undefined";
    var isBot = runningOnBrowser && !("onscroll" in window) || typeof navigator !== "undefined" && /(gle|ing|ro)bot|crawl|spider/i.test(navigator.userAgent);
    var supportsIntersectionObserver = runningOnBrowser && "IntersectionObserver" in window;
    var supportsClassList = runningOnBrowser && "classList" in document.createElement("p");
    var isHiDpi = runningOnBrowser && window.devicePixelRatio > 1;

    var defaultSettings = {
        elements_selector: ".lazy",
        container: isBot || runningOnBrowser ? document : null,
        threshold: 300,
        thresholds: null,
        data_src: "src",
        data_srcset: "srcset",
        data_sizes: "sizes",
        data_bg: "bg",
        data_bg_hidpi: "bg-hidpi",
        data_bg_multi: "bg-multi",
        data_bg_multi_hidpi: "bg-multi-hidpi",
        data_bg_set: "bg-set",
        data_poster: "poster",
        class_applied: "applied",
        class_loading: "loading",
        class_loaded: "loaded",
        class_error: "error",
        class_entered: "entered",
        class_exited: "exited",
        unobserve_completed: true,
        unobserve_entered: false,
        cancel_on_exit: true,
        callback_enter: null,
        callback_exit: null,
        callback_applied: null,
        callback_loading: null,
        callback_loaded: null,
        callback_error: null,
        callback_finish: null,
        callback_cancel: null,
        use_native: false,
        restore_on_error: false
    };
    var getExtendedSettings = function getExtendedSettings(customSettings) {
        return _extends({}, defaultSettings, customSettings);
    };

    /* Creates instance and notifies it through the window element */
    var createInstance = function createInstance(classObj, options) {
        var event;
        var eventString = "LazyLoad::Initialized";
        var instance = new classObj(options);

        try {
            // Works in modern browsers
            event = new CustomEvent(eventString, {
                detail: {
                    instance: instance
                }
            });
        } catch (err) {
            // Works in Internet Explorer (all versions)
            event = document.createEvent("CustomEvent");
            event.initCustomEvent(eventString, false, false, {
                instance: instance
            });
        }

        window.dispatchEvent(event);
    };
    /* Auto initialization of one or more instances of lazyload, depending on the
        options passed in (plain object or an array) */


    var autoInitialize = function autoInitialize(classObj, options) {
        if (!options) {
            return;
        }

        if (!options.length) {
            // Plain object
            createInstance(classObj, options);
        } else {
            // Array of objects
            for (var i = 0, optionsItem; optionsItem = options[i]; i += 1) {
                createInstance(classObj, optionsItem);
            }
        }
    };

    var SRC = "src";
    var SRCSET = "srcset";
    var SIZES = "sizes";
    var POSTER = "poster";
    var ORIGINALS = "llOriginalAttrs";
    var DATA = "data";

    var statusLoading = "loading";
    var statusLoaded = "loaded";
    var statusApplied = "applied";
    var statusEntered = "entered";
    var statusError = "error";
    var statusNative = "native";

    var dataPrefix = "data-";
    var statusDataName = "ll-status";
    var getData = function getData(element, attribute) {
        return element.getAttribute(dataPrefix + attribute);
    };
    var setData = function setData(element, attribute, value) {
        var attrName = dataPrefix + attribute;

        if (value === null) {
            element.removeAttribute(attrName);
            return;
        }

        element.setAttribute(attrName, value);
    };
    var getStatus = function getStatus(element) {
        return getData(element, statusDataName);
    };
    var setStatus = function setStatus(element, status) {
        return setData(element, statusDataName, status);
    };
    var resetStatus = function resetStatus(element) {
        return setStatus(element, null);
    };
    var hasEmptyStatus = function hasEmptyStatus(element) {
        return getStatus(element) === null;
    };
    var hasStatusLoading = function hasStatusLoading(element) {
        return getStatus(element) === statusLoading;
    };
    var hasStatusError = function hasStatusError(element) {
        return getStatus(element) === statusError;
    };
    var hasStatusNative = function hasStatusNative(element) {
        return getStatus(element) === statusNative;
    };
    var statusesAfterLoading = [statusLoading, statusLoaded, statusApplied, statusError];
    var hadStartedLoading = function hadStartedLoading(element) {
        return statusesAfterLoading.indexOf(getStatus(element)) >= 0;
    };

    var safeCallback = function safeCallback(callback, arg1, arg2, arg3) {
        if (!callback) {
            return;
        }

        if (arg3 !== undefined) {
            callback(arg1, arg2, arg3);
            return;
        }

        if (arg2 !== undefined) {
            callback(arg1, arg2);
            return;
        }

        callback(arg1);
    };

    var addClass = function addClass(element, className) {
        if (supportsClassList) {
            element.classList.add(className);
            return;
        }

        element.className += (element.className ? " " : "") + className;
    };
    var removeClass = function removeClass(element, className) {
        if (supportsClassList) {
            element.classList.remove(className);
            return;
        }

        element.className = element.className.replace(new RegExp("(^|\\s+)" + className + "(\\s+|$)"), " ").replace(/^\s+/, "").replace(/\s+$/, "");
    };

    var addTempImage = function addTempImage(element) {
        element.llTempImage = document.createElement("IMG");
    };
    var deleteTempImage = function deleteTempImage(element) {
        delete element.llTempImage;
    };
    var getTempImage = function getTempImage(element) {
        return element.llTempImage;
    };

    var unobserve = function unobserve(element, instance) {
        if (!instance) return;
        var observer = instance._observer;
        if (!observer) return;
        observer.unobserve(element);
    };
    var resetObserver = function resetObserver(observer) {
        observer.disconnect();
    };
    var unobserveEntered = function unobserveEntered(element, settings, instance) {
        if (settings.unobserve_entered) unobserve(element, instance);
    };

    var updateLoadingCount = function updateLoadingCount(instance, delta) {
        if (!instance) return;
        instance.loadingCount += delta;
    };
    var decreaseToLoadCount = function decreaseToLoadCount(instance) {
        if (!instance) return;
        instance.toLoadCount -= 1;
    };
    var setToLoadCount = function setToLoadCount(instance, value) {
        if (!instance) return;
        instance.toLoadCount = value;
    };
    var isSomethingLoading = function isSomethingLoading(instance) {
        return instance.loadingCount > 0;
    };
    var haveElementsToLoad = function haveElementsToLoad(instance) {
        return instance.toLoadCount > 0;
    };

    var getSourceTags = function getSourceTags(parentTag) {
        var sourceTags = [];

        for (var i = 0, childTag; childTag = parentTag.children[i]; i += 1) {
            if (childTag.tagName === "SOURCE") {
                sourceTags.push(childTag);
            }
        }

        return sourceTags;
    };

    var forEachPictureSource = function forEachPictureSource(element, fn) {
        var parent = element.parentNode;

        if (!parent || parent.tagName !== "PICTURE") {
            return;
        }

        var sourceTags = getSourceTags(parent);
        sourceTags.forEach(fn);
    };
    var forEachVideoSource = function forEachVideoSource(element, fn) {
        var sourceTags = getSourceTags(element);
        sourceTags.forEach(fn);
    };

    var attrsSrc = [SRC];
    var attrsSrcPoster = [SRC, POSTER];
    var attrsSrcSrcsetSizes = [SRC, SRCSET, SIZES];
    var attrsData = [DATA];
    var hasOriginalAttrs = function hasOriginalAttrs(element) {
        return !!element[ORIGINALS];
    };
    var getOriginalAttrs = function getOriginalAttrs(element) {
        return element[ORIGINALS];
    };
    var deleteOriginalAttrs = function deleteOriginalAttrs(element) {
        return delete element[ORIGINALS];
    }; // ## SAVE ##

    var setOriginalsObject = function setOriginalsObject(element, attributes) {
        if (hasOriginalAttrs(element)) {
            return;
        }

        var originals = {};
        attributes.forEach(function (attribute) {
            originals[attribute] = element.getAttribute(attribute);
        });
        element[ORIGINALS] = originals;
    };
    var saveOriginalBackgroundStyle = function saveOriginalBackgroundStyle(element) {
        if (hasOriginalAttrs(element)) {
            return;
        }

        element[ORIGINALS] = {
            backgroundImage: element.style.backgroundImage
        };
    }; // ## RESTORE ##

    var setOrResetAttribute = function setOrResetAttribute(element, attrName, value) {
        if (!value) {
            element.removeAttribute(attrName);
            return;
        }

        element.setAttribute(attrName, value);
    };

    var restoreOriginalAttrs = function restoreOriginalAttrs(element, attributes) {
        if (!hasOriginalAttrs(element)) {
            return;
        }

        var originals = getOriginalAttrs(element);
        attributes.forEach(function (attribute) {
            setOrResetAttribute(element, attribute, originals[attribute]);
        });
    };
    var restoreOriginalBgImage = function restoreOriginalBgImage(element) {
        if (!hasOriginalAttrs(element)) {
            return;
        }

        var originals = getOriginalAttrs(element);
        element.style.backgroundImage = originals.backgroundImage;
    };

    var manageApplied = function manageApplied(element, settings, instance) {
        addClass(element, settings.class_applied);
        setStatus(element, statusApplied); // Instance is not provided when loading is called from static class

        if (!instance) return;

        if (settings.unobserve_completed) {
            // Unobserve now because we can't do it on load
            unobserve(element, settings);
        }

        safeCallback(settings.callback_applied, element, instance);
    };
    var manageLoading = function manageLoading(element, settings, instance) {
        addClass(element, settings.class_loading);
        setStatus(element, statusLoading); // Instance is not provided when loading is called from static class

        if (!instance) return;
        updateLoadingCount(instance, +1);
        safeCallback(settings.callback_loading, element, instance);
    };
    var setAttributeIfValue = function setAttributeIfValue(element, attrName, value) {
        if (!value) {
            return;
        }

        element.setAttribute(attrName, value);
    };
    var setImageAttributes = function setImageAttributes(element, settings) {
        setAttributeIfValue(element, SIZES, getData(element, settings.data_sizes));
        setAttributeIfValue(element, SRCSET, getData(element, settings.data_srcset));
        setAttributeIfValue(element, SRC, getData(element, settings.data_src));
    };
    var setSourcesImg = function setSourcesImg(imgEl, settings) {
        forEachPictureSource(imgEl, function (sourceTag) {
            setOriginalsObject(sourceTag, attrsSrcSrcsetSizes);
            setImageAttributes(sourceTag, settings);
        });
        setOriginalsObject(imgEl, attrsSrcSrcsetSizes);
        setImageAttributes(imgEl, settings);
    };
    var setSourcesIframe = function setSourcesIframe(iframe, settings) {
        setOriginalsObject(iframe, attrsSrc);
        setAttributeIfValue(iframe, SRC, getData(iframe, settings.data_src));
    };
    var setSourcesVideo = function setSourcesVideo(videoEl, settings) {
        forEachVideoSource(videoEl, function (sourceEl) {
            setOriginalsObject(sourceEl, attrsSrc);
            setAttributeIfValue(sourceEl, SRC, getData(sourceEl, settings.data_src));
        });
        setOriginalsObject(videoEl, attrsSrcPoster);
        setAttributeIfValue(videoEl, POSTER, getData(videoEl, settings.data_poster));
        setAttributeIfValue(videoEl, SRC, getData(videoEl, settings.data_src));
        videoEl.load();
    };
    var setSourcesObject = function setSourcesObject(object, settings) {
        setOriginalsObject(object, attrsData);
        setAttributeIfValue(object, DATA, getData(object, settings.data_src));
    };
    var setBackground = function setBackground(element, settings, instance) {
        var bg1xValue = getData(element, settings.data_bg);
        var bgHiDpiValue = getData(element, settings.data_bg_hidpi);
        var bgDataValue = isHiDpi && bgHiDpiValue ? bgHiDpiValue : bg1xValue;
        if (!bgDataValue) return;
        element.style.backgroundImage = "url(\"".concat(bgDataValue, "\")");
        getTempImage(element).setAttribute(SRC, bgDataValue);
        manageLoading(element, settings, instance);
    }; // NOTE: THE TEMP IMAGE TRICK CANNOT BE DONE WITH data-multi-bg
    // BECAUSE INSIDE ITS VALUES MUST BE WRAPPED WITH URL() AND ONE OF THEM
    // COULD BE A GRADIENT BACKGROUND IMAGE

    var setMultiBackground = function setMultiBackground(element, settings, instance) {
        var bg1xValue = getData(element, settings.data_bg_multi);
        var bgHiDpiValue = getData(element, settings.data_bg_multi_hidpi);
        var bgDataValue = isHiDpi && bgHiDpiValue ? bgHiDpiValue : bg1xValue;

        if (!bgDataValue) {
            return;
        }

        element.style.backgroundImage = bgDataValue;
        manageApplied(element, settings, instance);
    };
    var setImgsetBackground = function setImgsetBackground(element, settings, instance) {
        var bgImgSetDataValue = getData(element, settings.data_bg_set);

        if (!bgImgSetDataValue) {
            return;
        }

        var imgSetValues = bgImgSetDataValue.split("|");
        var bgImageValues = imgSetValues.map(function (value) {
            return "image-set(".concat(value, ")");
        });
        element.style.backgroundImage = bgImageValues.join(); // Temporary fix for Chromeium with the -webkit- prefix

        if (element.style.backgroundImage === '') {
            bgImageValues = imgSetValues.map(function (value) {
                return "-webkit-image-set(".concat(value, ")");
            });
            element.style.backgroundImage = bgImageValues.join();
        }

        manageApplied(element, settings, instance);
    };
    var setSourcesFunctions = {
        IMG: setSourcesImg,
        IFRAME: setSourcesIframe,
        VIDEO: setSourcesVideo,
        OBJECT: setSourcesObject
    };
    var setSourcesNative = function setSourcesNative(element, settings) {
        var setSourcesFunction = setSourcesFunctions[element.tagName];

        if (!setSourcesFunction) {
            return;
        }

        setSourcesFunction(element, settings);
    };
    var setSources = function setSources(element, settings, instance) {
        var setSourcesFunction = setSourcesFunctions[element.tagName];

        if (!setSourcesFunction) {
            return;
        }

        setSourcesFunction(element, settings);
        manageLoading(element, settings, instance);
    };

    var elementsWithLoadEvent = ["IMG", "IFRAME", "VIDEO", "OBJECT"];
    var hasLoadEvent = function hasLoadEvent(element) {
        return elementsWithLoadEvent.indexOf(element.tagName) > -1;
    };
    var checkFinish = function checkFinish(settings, instance) {
        if (instance && !isSomethingLoading(instance) && !haveElementsToLoad(instance)) {
            safeCallback(settings.callback_finish, instance);
        }
    };
    var addEventListener = function addEventListener(element, eventName, handler) {
        element.addEventListener(eventName, handler);
        element.llEvLisnrs[eventName] = handler;
    };
    var removeEventListener = function removeEventListener(element, eventName, handler) {
        element.removeEventListener(eventName, handler);
    };
    var hasEventListeners = function hasEventListeners(element) {
        return !!element.llEvLisnrs;
    };
    var addEventListeners = function addEventListeners(element, loadHandler, errorHandler) {
        if (!hasEventListeners(element)) element.llEvLisnrs = {};
        var loadEventName = element.tagName === "VIDEO" ? "loadeddata" : "load";
        addEventListener(element, loadEventName, loadHandler);
        addEventListener(element, "error", errorHandler);
    };
    var removeEventListeners = function removeEventListeners(element) {
        if (!hasEventListeners(element)) {
            return;
        }

        var eventListeners = element.llEvLisnrs;

        for (var eventName in eventListeners) {
            var handler = eventListeners[eventName];
            removeEventListener(element, eventName, handler);
        }

        delete element.llEvLisnrs;
    };
    var doneHandler = function doneHandler(element, settings, instance) {
        deleteTempImage(element);
        updateLoadingCount(instance, -1);
        decreaseToLoadCount(instance);
        removeClass(element, settings.class_loading);

        if (settings.unobserve_completed) {
            unobserve(element, instance);
        }
    };
    var loadHandler = function loadHandler(event, element, settings, instance) {
        var goingNative = hasStatusNative(element);
        doneHandler(element, settings, instance);
        addClass(element, settings.class_loaded);
        setStatus(element, statusLoaded);
        safeCallback(settings.callback_loaded, element, instance);
        if (!goingNative) checkFinish(settings, instance);
    };
    var errorHandler = function errorHandler(event, element, settings, instance) {
        var goingNative = hasStatusNative(element);
        doneHandler(element, settings, instance);
        addClass(element, settings.class_error);
        setStatus(element, statusError);
        safeCallback(settings.callback_error, element, instance);
        if (settings.restore_on_error) restoreOriginalAttrs(element, attrsSrcSrcsetSizes);
        if (!goingNative) checkFinish(settings, instance);
    };
    var addOneShotEventListeners = function addOneShotEventListeners(element, settings, instance) {
        var elementToListenTo = getTempImage(element) || element;

        if (hasEventListeners(elementToListenTo)) {
            // This happens when loading is retried twice
            return;
        }

        var _loadHandler = function _loadHandler(event) {
            loadHandler(event, element, settings, instance);
            removeEventListeners(elementToListenTo);
        };

        var _errorHandler = function _errorHandler(event) {
            errorHandler(event, element, settings, instance);
            removeEventListeners(elementToListenTo);
        };

        addEventListeners(elementToListenTo, _loadHandler, _errorHandler);
    };

    var loadBackground = function loadBackground(element, settings, instance) {
        addTempImage(element);
        addOneShotEventListeners(element, settings, instance);
        saveOriginalBackgroundStyle(element);
        setBackground(element, settings, instance);
        setMultiBackground(element, settings, instance);
        setImgsetBackground(element, settings, instance);
    };

    var loadRegular = function loadRegular(element, settings, instance) {
        addOneShotEventListeners(element, settings, instance);
        setSources(element, settings, instance);
    };

    var load = function load(element, settings, instance) {
        if (hasLoadEvent(element)) {
            loadRegular(element, settings, instance);
        } else {
            loadBackground(element, settings, instance);
        }
    };
    var loadNative = function loadNative(element, settings, instance) {
        element.setAttribute("loading", "lazy");
        addOneShotEventListeners(element, settings, instance);
        setSourcesNative(element, settings);
        setStatus(element, statusNative);
    };

    var removeImageAttributes = function removeImageAttributes(element) {
        element.removeAttribute(SRC);
        element.removeAttribute(SRCSET);
        element.removeAttribute(SIZES);
    };

    var resetSourcesImg = function resetSourcesImg(element) {
        forEachPictureSource(element, function (sourceTag) {
            removeImageAttributes(sourceTag);
        });
        removeImageAttributes(element);
    };

    var restoreImg = function restoreImg(imgEl) {
        forEachPictureSource(imgEl, function (sourceEl) {
            restoreOriginalAttrs(sourceEl, attrsSrcSrcsetSizes);
        });
        restoreOriginalAttrs(imgEl, attrsSrcSrcsetSizes);
    };
    var restoreVideo = function restoreVideo(videoEl) {
        forEachVideoSource(videoEl, function (sourceEl) {
            restoreOriginalAttrs(sourceEl, attrsSrc);
        });
        restoreOriginalAttrs(videoEl, attrsSrcPoster);
        videoEl.load();
    };
    var restoreIframe = function restoreIframe(iframeEl) {
        restoreOriginalAttrs(iframeEl, attrsSrc);
    };
    var restoreObject = function restoreObject(objectEl) {
        restoreOriginalAttrs(objectEl, attrsData);
    };
    var restoreFunctions = {
        IMG: restoreImg,
        IFRAME: restoreIframe,
        VIDEO: restoreVideo,
        OBJECT: restoreObject
    };

    var restoreAttributes = function restoreAttributes(element) {
        var restoreFunction = restoreFunctions[element.tagName];

        if (!restoreFunction) {
            restoreOriginalBgImage(element);
            return;
        }

        restoreFunction(element);
    };

    var resetClasses = function resetClasses(element, settings) {
        if (hasEmptyStatus(element) || hasStatusNative(element)) {
            return;
        }

        removeClass(element, settings.class_entered);
        removeClass(element, settings.class_exited);
        removeClass(element, settings.class_applied);
        removeClass(element, settings.class_loading);
        removeClass(element, settings.class_loaded);
        removeClass(element, settings.class_error);
    };

    var restore = function restore(element, settings) {
        restoreAttributes(element);
        resetClasses(element, settings);
        resetStatus(element);
        deleteOriginalAttrs(element);
    };

    var cancelLoading = function cancelLoading(element, entry, settings, instance) {
        if (!settings.cancel_on_exit) return;
        if (!hasStatusLoading(element)) return;
        if (element.tagName !== "IMG") return; //Works only on images

        removeEventListeners(element);
        resetSourcesImg(element);
        restoreImg(element);
        removeClass(element, settings.class_loading);
        updateLoadingCount(instance, -1);
        resetStatus(element);
        safeCallback(settings.callback_cancel, element, entry, instance);
    };

    var onEnter = function onEnter(element, entry, settings, instance) {
        var dontLoad = hadStartedLoading(element);
        /* Save status
        before setting it, to prevent loading it again. Fixes #526. */

        setStatus(element, statusEntered);
        addClass(element, settings.class_entered);
        removeClass(element, settings.class_exited);
        unobserveEntered(element, settings, instance);
        safeCallback(settings.callback_enter, element, entry, instance);
        if (dontLoad) return;
        load(element, settings, instance);
    };
    var onExit = function onExit(element, entry, settings, instance) {
        if (hasEmptyStatus(element)) return; //Ignore the first pass, at landing

        addClass(element, settings.class_exited);
        cancelLoading(element, entry, settings, instance);
        safeCallback(settings.callback_exit, element, entry, instance);
    };

    var tagsWithNativeLazy = ["IMG", "IFRAME", "VIDEO"];
    var shouldUseNative = function shouldUseNative(settings) {
        return settings.use_native && "loading" in HTMLImageElement.prototype;
    };
    var loadAllNative = function loadAllNative(elements, settings, instance) {
        elements.forEach(function (element) {
            if (tagsWithNativeLazy.indexOf(element.tagName) === -1) {
                return;
            }

            loadNative(element, settings, instance);
        });
        setToLoadCount(instance, 0);
    };

    var isIntersecting = function isIntersecting(entry) {
        return entry.isIntersecting || entry.intersectionRatio > 0;
    };

    var getObserverSettings = function getObserverSettings(settings) {
        return {
            root: settings.container === document ? null : settings.container,
            rootMargin: settings.thresholds || settings.threshold + "px"
        };
    };

    var intersectionHandler = function intersectionHandler(entries, settings, instance) {
        entries.forEach(function (entry) {
            return isIntersecting(entry) ? onEnter(entry.target, entry, settings, instance) : onExit(entry.target, entry, settings, instance);
        });
    };

    var observeElements = function observeElements(observer, elements) {
        elements.forEach(function (element) {
            observer.observe(element);
        });
    };
    var updateObserver = function updateObserver(observer, elementsToObserve) {
        resetObserver(observer);
        observeElements(observer, elementsToObserve);
    };
    var setObserver = function setObserver(settings, instance) {
        if (!supportsIntersectionObserver || shouldUseNative(settings)) {
            return;
        }

        instance._observer = new IntersectionObserver(function (entries) {
            intersectionHandler(entries, settings, instance);
        }, getObserverSettings(settings));
    };

    var toArray = function toArray(nodeSet) {
        return Array.prototype.slice.call(nodeSet);
    };
    var queryElements = function queryElements(settings) {
        return settings.container.querySelectorAll(settings.elements_selector);
    };
    var excludeManagedElements = function excludeManagedElements(elements) {
        return toArray(elements).filter(hasEmptyStatus);
    };
    var hasError = function hasError(element) {
        return hasStatusError(element);
    };
    var filterErrorElements = function filterErrorElements(elements) {
        return toArray(elements).filter(hasError);
    };
    var getElementsToLoad = function getElementsToLoad(elements, settings) {
        return excludeManagedElements(elements || queryElements(settings));
    };

    var retryLazyLoad = function retryLazyLoad(settings, instance) {
        var errorElements = filterErrorElements(queryElements(settings));
        errorElements.forEach(function (element) {
            removeClass(element, settings.class_error);
            resetStatus(element);
        });
        instance.update();
    };
    var setOnlineCheck = function setOnlineCheck(settings, instance) {
        if (!runningOnBrowser) {
            return;
        }

        instance._onlineHandler = function () {
            retryLazyLoad(settings, instance);
        };

        window.addEventListener("online", instance._onlineHandler);
    };
    var resetOnlineCheck = function resetOnlineCheck(instance) {
        if (!runningOnBrowser) {
            return;
        }

        window.removeEventListener("online", instance._onlineHandler);
    };

    var LazyLoad = function LazyLoad(customSettings, elements) {
        var settings = getExtendedSettings(customSettings);
        this._settings = settings;
        this.loadingCount = 0;
        setObserver(settings, this);
        setOnlineCheck(settings, this);
        this.update(elements);
    };

    LazyLoad.prototype = {
        update: function update(givenNodeset) {
            var settings = this._settings;
            var elementsToLoad = getElementsToLoad(givenNodeset, settings);
            setToLoadCount(this, elementsToLoad.length);

            if (isBot || !supportsIntersectionObserver) {
                this.loadAll(elementsToLoad);
                return;
            }

            if (shouldUseNative(settings)) {
                loadAllNative(elementsToLoad, settings, this);
                return;
            }

            updateObserver(this._observer, elementsToLoad);
        },
        destroy: function destroy() {
            // Observer
            if (this._observer) {
                this._observer.disconnect();
            } // Clean handlers


            resetOnlineCheck(this); // Clean custom attributes on elements

            queryElements(this._settings).forEach(function (element) {
                deleteOriginalAttrs(element);
            }); // Delete all internal props

            delete this._observer;
            delete this._settings;
            delete this._onlineHandler;
            delete this.loadingCount;
            delete this.toLoadCount;
        },
        loadAll: function loadAll(elements) {
            var _this = this;

            var settings = this._settings;
            var elementsToLoad = getElementsToLoad(elements, settings);
            elementsToLoad.forEach(function (element) {
                unobserve(element, _this);
                load(element, settings, _this);
            });
        },
        restoreAll: function restoreAll() {
            var settings = this._settings;
            queryElements(settings).forEach(function (element) {
                restore(element, settings);
            });
        }
    };

    LazyLoad.load = function (element, customSettings) {
        var settings = getExtendedSettings(customSettings);
        load(element, settings);
    };

    LazyLoad.resetStatus = function (element) {
        resetStatus(element);
    }; // Automatic instances creation if required (useful for async script loading)


    if (runningOnBrowser) {
        autoInitialize(LazyLoad, window.lazyLoadOptions);
    }


export { LazyLoad as default }
